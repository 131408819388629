<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">操作日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div
          class="operationControl removeStudentLogList"
          style="display: flex;flex-direction: column;align-items: flex-start; "
        >
          <div class="searchbox">
            <div title="操作账号" class="searchboxItem ci-full">
              <span class="itemLabel">操作账号:</span>
              <el-input
                v-model="opAccount"
                clearable
                type="text"
                size="small"
                placeholder="请输入操作账号"
              />
            </div>
            <div title="操作人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">操作人姓名:</span>
              <el-input
                v-model="opRealname"
                clearable
                type="text"
                size="small"
                placeholder="请输入操作人姓名"
              />
            </div>
            <div title="操作内容" class="searchboxItem ci-full">
              <span class="itemLabel">操作内容:</span>
              <el-select
                v-model="opType"
                placeholder="请选择操作内容"
                size="small"
                clearable
              >
                <el-option
                  v-for="item in OpTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="操作描述" class="searchboxItem ci-full">
              <span class="itemLabel">操作描述:</span>
              <el-input
                v-model="opDesc"
                clearable
                type="text"
                size="small"
                placeholder="请输入操作描述"
              />
            </div>
          </div>
          <div class="searchbox" style="padding-top:10px">
            <div title="操作时间" class="searchboxItem ci-full">
              <span class="itemLabel">操作时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="Time"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="timestamp"
              ></el-date-picker>
            </div>
            <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
            >
            <el-button
              type="primary"
              class="bgc-bv"
              v-if="userJson.roleId == '1'"
              round
              @click="ExportDelayLog()"
              >导出班级延期日志</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              stripe
              :header-cell-style="tableHeader"
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="60"
                fixed
              />
              <el-table-column
                label="操作账号"
                align="center"
                show-overflow-tooltip
                prop="opAccount"
              />
              <el-table-column
                label="操作人姓名"
                align="center"
                show-overflow-tooltip
                prop="opRealname"
              />
              <el-table-column
                label="操作IP"
                align="center"
                show-overflow-tooltip
                prop="opIp"
              />
              <el-table-column
                label="操作内容"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{ $setDictionary("OPERATIONLOG", scope.row.opType) }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作描述"
                align="center"
                :show-overflow-tooltip="false"
                prop="opDesc"
                min-width="200"
              />
              <el-table-column
                label="操作时间"
                align="center"
                show-overflow-tooltip
                prop="createTime"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="导出提示"
      :visible.sync="DelaylogDialog"
      width="400px"
      top="5%"
      :center="true"
      :before-close="closeDocsDialog"
    >
      <div class="model">
        <div class="conTip">
          导出数据仅限于业务范围内使用，不得用于其它用途，如因个人原因导致数据泄露，由个人承担法律责任。
        </div>
        <div class="flexca">
          <div class="flexac" style="width: 7rem; margin-right: 10px">
            <!-- <span class="itemLabel">请选择导出范围:</span> -->
            <el-select
              size="small"
              v-model="exportName"
              @change="changeLoadType"
            >
              <el-option value="projectName" label="班级名称"></el-option>
              <el-option value="projectCode" label="班级编码"></el-option>
            </el-select>
          </div>
          <el-input
            v-model="projectName"
            v-if="exportName == 'projectName'"
            size="small"
            placeholder="请输入班级名称"
            style="width: 17rem"
          ></el-input>
          <el-input
            v-model="projectCode"
            v-else
            size="small"
            placeholder="请输入班级编码"
            style="width: 17rem"
          ></el-input>
        </div>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
        style="display: flex; justify-content: center;"
      >
        <div>
          <el-button class="bgc-bv" @click="confirmDocsDialog">确定</el-button>
          <el-button @click="closeDocsDialog">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { Message } from "element-ui";
import { mapGetters } from "vuex";
export default {
  name: "removeStudentLogList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      opAccount: "",
      opRealname: "",
      opType: "",
      opDesc: "",
      Time: "",
      OpTypeList: [],
      DelaylogDialog: false,
      exportName: "projectName", // 导出名称
      projectCode: "",
      projectName: "",
      classListModel: [],
    };
  },
   computed: {
    ...mapGetters({
      userJson: "getUser",
    }),
  },
  created() {
    this.getOpTypeList();
  },
  methods: {
    ExportDelayLog() {
      this.DelaylogDialog = true;
    },
    closeDocsDialog() {
      this.DelaylogDialog = false;
       this.projectName = "";
      this.projectCode = "";
    },
    changeLoadType() {
      this.projectName = "";
      this.projectCode = "";
    },
    confirmDocsDialog() {
      const dataKey =
        this.exportName == "projectName" ? "projectName" : "projectCode";
      const dataValue = dataKey == "projectName" ? "projectName" : "projectCode";
      if (this[dataValue] != "") {
        this.$post("/operationlog/export", {
          // projectId: this.classId
          [dataKey]: this[dataValue],
        })
          .then((res) => {
            if (res.status == 0) {
              window.open(res.data.url);
              this.DelaylogDialog = false;
              this[dataValue] = "";
            }
          })
          .catch(() => {
            return;
          });
      } else {
        Message.error({
          message: "请输入班级名称或班级编码",
          duration: "1000",
        });
      }
    },
    // 班级名称搜索列表
    getClassListModel(query) {
      if (query.trim()) {
        this.$post("/biz/project/queryProjectNameList", { projectName: query })
          .then((res) => {
            if (res.status == 0) {
              this.classListModel = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.classListModel = [];
      }
    },
    getData(pageNum = 1) {
      let url = '/operationlog/queryList';
      if(this.userJson.roleId == '1') {
        url = '/operationlog/queryList'
      } else if(this.userJson.roleId == '3') {
        url = '/operationlog/org/list'
      }
      
      let params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        opAccount: this.opAccount,
        opRealname: this.opRealname,
        opType: this.opType,
        opDesc: this.opDesc,
      };
      if (this.Time && this.Time.length) {
        params.startDate = this.Time[0];
        params.endDate = this.Time[1];
      }

      this.doFetch({
        url: url,
        params,
        pageNum,
      });
    },
    getOpTypeList() {
      const list = this.$setDictionary("OPERATIONLOG", "list");
      const newList = [];
      for (const key in list) {
        if(this.userJson.roleId == '1') {
          newList.push({
          value: key,
          label: list[key],
        });
        } else if(this.userJson.roleId == '3') {
          if(key == 'OP01') {
             newList.push({
          value: key,
          label: list[key],
        });
          }
        }
        
      }
      this.OpTypeList = newList;
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4) * 16;
      if (opDom) {
        tHeight -= 40 + 40 + 0.675 * 16 - 80;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.model {
  .conTip {
    text-align: justify;
    margin-bottom: 20px;
  }
  .itemLabel {
    margin: 10px 0;
  }
}
// .removeStudentLogList {
//   .searchbox {
//     .searchboxItem {
//       .itemLabel {
//         min-width: 14rem;
//       }
//     }
//   }
// }
</style>
